import React, { useState } from 'react';
import './FeedbackList.css';

function FeedbackList({ feedbacks, handleNewFeedback }) {
    const [currentPage, setCurrentPage] = useState(1);
    const feedbacksPerPage = 5; // Number of feedback items per page

    // Calculate the indices for the feedbacks to display
    const indexOfLastFeedback = currentPage * feedbacksPerPage;
    const indexOfFirstFeedback = indexOfLastFeedback - feedbacksPerPage;
    const currentFeedbacks = feedbacks.slice(indexOfFirstFeedback, indexOfLastFeedback);

    const totalPages = Math.ceil(feedbacks.length / feedbacksPerPage);

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this feedback?");
        if (!confirmDelete) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/feedback.php?id=${id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                alert("Feedback deleted successfully.");
                handleNewFeedback(feedbacks.filter(feedback => feedback.id !== id));
            } else {
                alert("Failed to delete feedback.");
            }
        } catch (error) {
            console.error("Error deleting feedback:", error);
        }
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className='feedback-list-content'>
            <h2>Feedback List</h2>
            {feedbacks.length > 0 ? (
                <>
                    <ul>
                        {currentFeedbacks.map((feedback) => (
                            <li key={feedback.id}>
                                <p>{feedback.text}</p>
                                <p><strong>Category:</strong> {feedback.category}</p>
                                <p><strong>Sentiment:</strong> {feedback.sentiment}</p>
                                <p><strong>Date:</strong> {feedback.date_submitted}</p>
                                <button onClick={() => handleDelete(feedback.id)} className="delete-button">Delete</button>
                            </li>
                        ))}
                    </ul>
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            ) : (
                <p>No feedback found.</p>
            )}
        </div>
    );
}

export default FeedbackList;
