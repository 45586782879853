import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './CreateUser.css';

const EditAccount = () => {
  const { id, type } = useParams();
  const [initialData, setInitialData] = useState({});
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    name: '',
    description: '',
    logo: null,
    backgroundColor: '#ffffff',
  });
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (type && id) {
    const url = type === 'user'
        ? `${process.env.REACT_APP_BACKEND_URL}/api/users.php?id=${id}`
        : `${process.env.REACT_APP_BACKEND_URL}/api/clients.php?id=${id}`;

      axios.get(url)
        .then((response) => {
          const data = response.data;
          setInitialData(data);

          if (type === 'user') {
            setFormData({
              username: data.username || '',
              email: data.email || '',
              password: '',
            });
          } else {
            setFormData({
              name: data.name || '',
              description: data.description || '',
              logo: null,
              backgroundColor: data.background_color || '#ffffff',
              shareLink: data.share_link || '',
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching account details!", error);
        });
    }
  }, [id, type]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'logo') {
      setFormData({ ...formData, logo: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('id', id);
    data.append('type', type);

    // Conditionally add fields only if changed
    if (type === 'user') {
      if (formData.username && formData.username !== initialData.username) {
        data.append('username', formData.username);
      }
      if (formData.email && formData.email !== initialData.email) {
        data.append('email', formData.email);
      }
      if (formData.password) {
        data.append('password', formData.password);
      }
    } else {
      if (formData.name && formData.name !== initialData.name) {
        data.append('name', formData.name);
      }
      if (formData.description && formData.description !== initialData.description) {
        data.append('description', formData.description);
      }
      if (formData.backgroundColor && formData.backgroundColor !== initialData.background_color) {
        data.append('background_color', formData.backgroundColor);
      }
      if (formData.logo) {
        data.append('logo', formData.logo);
      }
      if (formData.shareLink && formData.shareLink !== initialData.share_link) {
        data.append('share_link', formData.shareLink);
      }
    }

    axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/edit-account.php`, data)
      .then((response) => {
        setMessage("Account updated successfully!");
        setIsError(false);
      })
      .catch((error) => {
        setMessage("There was an error updating the account.");
        setIsError(true);
        console.error("Error updating account!", error);
      });
  };

  return (
    <div className="create-user">
      <div className="create-user-form">
        <div className="edit-account-form">
          <h2>Edit {type === 'user' ? 'User' : 'Client'} Account</h2>

          {message && (
            <div style={{ color: isError ? 'red' : 'green', marginBottom: '20px' }}>
              {message}
            </div>
          )}

          <form onSubmit={handleSubmit} encType="multipart/form-data">
            {type === 'user' && (
              <>
                <label>
                  <p>Username:</p>
                  <input
                    type="text"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />
                </label>
                <br />
                <label>
                  <p>Email:</p>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </label>
                <br />
                <label>
                  <p>Password:</p>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Leave blank to keep current password"
                  />
                </label>
                <br />
              </>
            )}

            {type === 'client' && (
              <>
                <label>
                  <p>Name:</p>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </label>
                <br />
                <label>
                  <p>Description:</p>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </label>
                <br />

                {/* Show the current logo if one exists in the DB */}
                {initialData.logo && (
                  <div>
                    <p>Current Logo:</p>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/${initialData.logo}`}
                      alt="Current Logo"
                      style={{ width: '100px', height: 'auto' }}
                    />
                  </div>
                )}

                <label>
                  <p>New Logo (optional):</p>
                  <input
                    type="file"
                    name="logo"
                    onChange={handleChange}
                  />
                </label>
                <br />


                {/* --New Field for "share link" */}
                <label>
                  <p>Share Link (optional):</p>
                  <input
                    type="text"
                    name="shareLink"
                    value={formData.shareLink}
                    onChange={handleChange}
                  />
                </label>
                <br />

                <label>
                  <p>Background Color:</p>
                  <input
                    type="color"
                    name="backgroundColor"
                    value={formData.backgroundColor}
                    onChange={handleChange}
                  />
                </label>
              </>
            )}

            <button type="submit">Update</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAccount;
