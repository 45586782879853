import React from 'react';
import { useSpring, animated } from 'react-spring';

const Odometer = ({ value }) => {
    const { animatedValue } = useSpring({
        animatedValue: value,
        from: { animatedValue: 0 },
        config: { tension: 120, friction: 14 },
    });

    return (
        <animated.div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#fff' }}>
            {animatedValue.to(val => Math.floor(val))}
        </animated.div>
    );
};

export default Odometer;
