import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CreateUser.css';

function CreateUser() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [role, setRole] = useState("admin");
    const [clientName, setClientName] = useState("");
    const [description, setDescription] = useState("");
    const [logo, setLogo] = useState(null);
    const [background, setBackground] = useState(null);
    const [backgroundColor, setBackgroundColor] = useState("#ffffff"); // Default background color
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleCreateUser = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Passwords do not match!");
            return;
        }

        const formData = new FormData();
        formData.append("username", username);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("role", role);

        // Append client-related data if the role is client
        if (role === "client") {
            formData.append("clientName", clientName);
            formData.append("description", description);
            formData.append("background_color", backgroundColor); // Add background color
            if (logo) formData.append("logo", logo);
            if (background) formData.append("background", background);
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users.php`, {
                method: "POST",
                body: formData,
            });

            const data = await response.json();

            if (response.ok) {
                alert(data.message || "User created successfully!");
                navigate('/');  // Redirect to login page
            } else {
                setError(data.message || "An error occurred.");
            }
        } catch (error) {
            setError("Failed to create user. Please try again later.");
        }
    };

    return (
        <div className='create-user'>
        <div className='create-user-form'>
            <h2>Create New User</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <form onSubmit={handleCreateUser}>
                <div className="create-user-form-item">
                    <label>Role:</label>
                    <select value={role} onChange={(e) => setRole(e.target.value)}>
                        <option value="admin">Admin</option>
                        <option value="client">Client</option>
                        <option value="client user">Client User</option>
                    </select>
                </div>
                <div className="create-user-form-item">
                    <label>Username:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="create-user-form-item">
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="create-user-form-item">
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="create-user-form-item">
                    <label>Confirm Password:</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>

                {role === "client" && (
                    <>
                        {/* Optional Client-related fields */}
                        <div className="create-user-form-item">
                            <label>Client Name:</label>
                            <input
                                type="text"
                                value={clientName}
                                onChange={(e) => setClientName(e.target.value)}
                                placeholder="Client Name"
                            />
                        </div>
                        <div className="create-user-form-item">
                            <label>Client Description:</label>
                            <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Client Description"
                            />
                        </div>
                        <div className="create-user-form-item">
                            <label>Client Logo:</label>
                            <input
                                type="file"
                                onChange={(e) => setLogo(e.target.files[0])}
                                accept="image/*"
                            />
                        </div>
                        <div className="create-user-form-item">
                            <label>Client Background:</label>
                            <input
                                type="file"
                                onChange={(e) => setBackground(e.target.files[0])}
                                accept="image/*"
                            />
                        </div>
                        <div className="create-user-form-item">
                            <label>Background Color:</label>
                            <input
                                type="color"
                                value={backgroundColor}
                                onChange={(e) => setBackgroundColor(e.target.value)}
                            />
                        </div>
                    </>
                )}

                <button type="submit">Create User</button>
            </form>
        </div>
        </div>
    );
}

export default CreateUser;
