import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ClientOptInsPage.css';

function ClientOptInsPage() {
  const { clientId } = useParams();
  const [clientDetails, setClientDetails] = useState(null);
  const [optIns, setOptIns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/clients.php?id=${clientId}`
        );
        const data = await response.json();

        if (response.ok && data) {
          setClientDetails(data.id ? data : {});
        } else {
          setError('Failed to load client details.');
        }
      } catch (err) {
        setError('An error occurred while fetching client details.');
      }
    };

    const fetchOptIns = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/optin.php?client_id=${clientId}`
        );
        const data = await response.json();

        if (response.ok && data.records) {
          setOptIns(data.records);
        } else {
          setError(data.message || 'Failed to load opt-in data.');
        }
      } catch (err) {
        setError('An error occurred while fetching opt-in data.');
      } finally {
        setLoading(false);
      }
    };

    fetchClientDetails();
    fetchOptIns();
  }, [clientId]);

  // Handler to delete an opt-in record by ID
  const handleDelete = async (optInId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this opt-in record?');
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/optin.php?id=${optInId}`,
        { method: 'DELETE' }
      );

      if (response.ok) {
        // Remove this opt-in from the state array
        setOptIns((prev) => prev.filter((optIn) => optIn.id !== optInId));
      } else {
        const data = await response.json();
        alert(data.message || 'Failed to delete opt-in record.');
      }
    } catch (error) {
      console.error('Error deleting opt-in:', error);
      alert('An error occurred while deleting the opt-in record.');
    }
  };

  return (
    <div className="client-optins-container">
      <div className="client-overlay">
        <div className="client-optins-container">
          {clientDetails?.logo && (
            <div className="client-logo-container">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}/${clientDetails.logo}`}
                alt={`${clientDetails.name} Logo`}
                className="client-logo"
              />
            </div>
          )}

          <h1>
            {clientDetails?.name ? `${clientDetails.name} - Opt-Ins` : 'Opt-Ins'}
          </h1>
          {loading ? (
            <p>Loading opt-ins...</p>
          ) : error ? (
            <p className="error">{error}</p>
          ) : optIns.length > 0 ? (
            <ul className="optins-list">
              {optIns.map((optIn) => (
                <li key={optIn.id} className="optin-item">
                  <div className="optin-data">
                    <strong>Name:</strong> {optIn.name}
                  </div>
                  <div className="optin-data">
                    <strong>Email:</strong> {optIn.email}
                  </div>
                  <div className="optin-data">
                    <strong>Phone:</strong> {optIn.phone}
                  </div>
                  {/* Example: show whether user opted in for email or SMS */}
                  <div className="optin-data">
                    <strong>Opt-in Email:</strong> {optIn.opt_in_email ? 'Yes' : 'No'}
                  </div>
                  <div className="optin-data">
                    <strong>Opt-in SMS:</strong> {optIn.opt_in_sms ? 'Yes' : 'No'}
                  </div>
                  {/* Delete button */}
                  <button className="delete-button" onClick={() => handleDelete(optIn.id)}>
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No opt-ins found for this client.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClientOptInsPage;
