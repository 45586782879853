import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ClientManagement.css'; // Import CSS for custom styling

function ClientManagement() {
    const [clients, setClients] = useState([]);
    const [newClientName, setNewClientName] = useState("");
    const [newClientDescription, setNewClientDescription] = useState("");
    const [newClientLogo, setNewClientLogo] = useState(null); // State for logo file
    const [newClientBackground, setNewClientBackground] = useState(null); // State for background file
    const [newClientUsername, setNewClientUsername] = useState("");
    const [newClientEmail, setNewClientEmail] = useState("");
    const [newClientPassword, setNewClientPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php`);
            
            if (response.ok) {
                const data = await response.json();
                if (data && data.records) {
                    setClients(data.records);
                } else {
                    setError("No clients found.");
                }
            } else {
                setError(`Failed to load clients: ${response.statusText}`);
            }
        } catch (error) {
            console.error("Error fetching clients:", error);
            setError("An error occurred while fetching clients.");
        }
    };

    const handleAddClient = async () => {
        if (!newClientName || !newClientUsername || !newClientEmail || !newClientPassword) {
            setError("Client name, username, email, and password are required.");
            return;
        }

        const formData = new FormData();
        formData.append('name', newClientName);
        formData.append('description', newClientDescription);
        formData.append('username', newClientUsername);
        formData.append('email', newClientEmail);
        formData.append('password', newClientPassword);
        if (newClientLogo) formData.append('logo', newClientLogo);
        if (newClientBackground) formData.append('background', newClientBackground);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                await fetchClients(); // Refresh the client list after adding
                setNewClientName(""); // Clear the input fields
                setNewClientDescription("");
                setNewClientUsername("");
                setNewClientEmail("");
                setNewClientPassword("");
                setNewClientLogo(null);
                setNewClientBackground(null);
            } else {
                setError("Failed to add client.");
            }
        } catch (error) {
            console.error("Error adding client:", error);
            setError("An error occurred while adding the client.");
        }
    };

    const handleDeleteClient = async (clientId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this client?");
        if (!confirmDelete) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php?id=${clientId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                await fetchClients(); // Refresh the client list after deletion
            } else {
                setError("Failed to delete client.");
            }
        } catch (error) {
            console.error("Error deleting client:", error);
            setError("An error occurred while deleting the client.");
        }
    };

    return (
        <div className="client-management-container">
            <h2>Client Management</h2>
            {error && <p className="error-message">{error}</p>}

            <h3>Add New Client</h3>
            <div className="add-client-form">
                <input
                    type="text"
                    value={newClientName}
                    onChange={(e) => setNewClientName(e.target.value)}
                    placeholder="Client Name"
                    required
                />
                <textarea
                    value={newClientDescription}
                    onChange={(e) => setNewClientDescription(e.target.value)}
                    placeholder="Client Description"
                />
                <input
                    type="text"
                    value={newClientUsername}
                    onChange={(e) => setNewClientUsername(e.target.value)}
                    placeholder="Client Username"
                    required
                />
                <input
                    type="email"
                    value={newClientEmail}
                    onChange={(e) => setNewClientEmail(e.target.value)}
                    placeholder="Client Email"
                    required
                />
                <input
                    type="password"
                    value={newClientPassword}
                    onChange={(e) => setNewClientPassword(e.target.value)}
                    placeholder="Client Password"
                    required
                />
                <input
                    type="file"
                    onChange={(e) => setNewClientLogo(e.target.files[0])}
                    accept="image/*"
                    className="file-input"
                />
                <input
                    type="file"
                    onChange={(e) => setNewClientBackground(e.target.files[0])}
                    accept="image/*"
                    className="file-input"
                />
                <button onClick={handleAddClient} className="add-client-button">Add Client</button>
            </div>

            <div className="existing-clients">
                <h3>Existing Clients</h3>
                <ul class="clients-list">
                    {clients.map(client => (
                        <li key={client.id} className="client-item">
                            <div className="client-info-home">
                                {/* {client.logo && <img src={`${process.env.REACT_APP_BACKEND_URL}/${client.logo}`} alt={`${client.name} logo`} className="client-logo" />} */}
                                <strong>{client.name}</strong>
                            </div>
                            <div className="client-actions">
                                <Link to={`/client/${client.id}/feedbacks`} className="client-link">
                                    View Feedback Summary
                                </Link>
                                <Link to={`/client/${client.id}/submit-feedback`} className="client-link">
                                    View Submit Feedback Page
                                </Link>
                                <button onClick={() => handleDeleteClient(client.id)} className="delete-client-button">
                                    Delete
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default ClientManagement;
