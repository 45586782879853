// OptInsModal.jsx
import React, { useEffect, useState } from 'react';
import './OptInsModal.css'; // Optional stylesheet for modal styling

function OptInsModal({ clientId, onClose }) {
  const [optIns, setOptIns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchOptIns() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/optin.php?client_id=${clientId}`
        );
        const data = await response.json();
  
        if (response.ok && data.records) {
          setOptIns(data.records);
        } else {
          setError(data.message || "Failed to load opt-in data.");
        }
      } catch (err) {
        setError("An error occurred while fetching opt-in data.");
      } finally {
        setLoading(false);
      }
    }
    fetchOptIns();
  }, [clientId]);
  

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h2>Client Opt-Ins</h2>

        {loading ? (
          <p>Loading opt-ins...</p>
        ) : error ? (
          <p className="error">{error}</p>
        ) : optIns.length > 0 ? (
          <ul>
            {optIns.map((optIn) => (
              <li key={optIn.id}>
                <strong>{optIn.name}</strong> - {optIn.email} 
                <strong>{optIn.name}</strong> - {optIn.phone}  
                {/* Render any other opt-in data you need */}
              </li>
            ))}
          </ul>
        ) : (
          <p>No opt-ins found for this client.</p>
        )}
      </div>
    </div>
  );
}

export default OptInsModal;
