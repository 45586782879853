import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';
import logo from "../img/echolink-horizontal-white.png";

const Navbar = ({ isAuthenticated, onLogout }) => {
    const [userId, setUserId] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false); // State for toggling menu
    const navigate = useNavigate();
    const location = useLocation();
    const userRole = localStorage.getItem('userRole');

    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
        } else {
            console.error("User ID not found in localStorage");
        }
    }, []);

    const handleLogout = () => {
        onLogout();
        navigate('/');
    };

    const isActive = (path) => location.pathname.includes(path);

    let clientIdMatch = location.pathname.match(/\/client\/(\d+)/);

    if (!clientIdMatch) {
        clientIdMatch = location.pathname.match(/\/optin\/(\d+)/);
    }
    
    const clientId = clientIdMatch ? clientIdMatch[1] : null;

    const isOnFeedbackPage = location.pathname.includes('feedbacks');
    const isOnOptInPage = location.pathname.includes('optin');

    return (
        isAuthenticated && userId && (
            <nav className="navbar">
                <div className="navbar-header">
                    <Link to={`/`}>
                        <img src={logo} alt="logo" />
                    </Link>
                    <button
                        className="hamburger-menu"
                        onClick={() => setMenuOpen(!menuOpen)}
                        aria-label="Toggle menu"
                    >
                        ☰
                    </button>
                </div>
                <div className={`navbar-menu`}>
                    <ul className="navbar-links">
                        <div className='navbar-links-right'>
                            {isOnFeedbackPage && clientId && (
                                <Link 
                                    to={`/client/${clientId}/submit-feedback`}
                                    className="navbar-button navbar-submit-button"
                                    target='_blank'
                                >
                                    Share Submit Feedback Page
                                </Link>
                            )}
                            {isOnOptInPage && clientId && (
                                <Link 
                                    to={`/opt-in/${clientId}/`}
                                    className="navbar-button navbar-submit-button"
                                    target='_blank'
                                >
                                    Share Opt-In Page
                                </Link>
                            )}
                            {userRole === 'admin' && (
                                <Link
                                    to={`/admin/dashboard`}
                                    className={isActive('/admin/dashboard') ? 'active-link' : ''}
                                >
                                    Clients
                                </Link>
                            )}
                            <Link
                                to={`/edit-account/${userRole === 'admin' ? 'user' : 'client'}/${userId}`}
                                className={isActive(`/edit-account`) ? 'active-link' : ''}
                            >
                                Account
                            </Link>
                        </div>
                        <div className="navbar-account">
                            <button className="logout-button" onClick={handleLogout}>Logout</button>
                        </div>
                    </ul>
                </div>
                <div className={`hamburger-contents ${menuOpen ? 'open' : ''}`}>

                     <button
                        className="hamburger-menu"
                        onClick={() => setMenuOpen(!menuOpen)}
                        aria-label="Toggle menu"
                    >
                        close menu
                    </button>
                    <ul className="navbar-links">
                        <div className='navbar-links-right'>
                            {isOnFeedbackPage && clientId && (
                                <Link 
                                    to={`/client/${clientId}/submit-feedback`}
                                    className="navbar-button navbar-submit-button"
                                    target='_blank'
                                >
                                    Share Submit Feedback Page
                                </Link>
                            )}
                            {isOnOptInPage && clientId && (
                                <Link 
                                    to={`/opt-in/${clientId}/`}
                                    className="navbar-button navbar-submit-button"
                                    target='_blank'
                                >
                                    Share Opt-In Page
                                </Link>
                            )}
                            {userRole === 'admin' && (
                                <Link
                                    to={`/admin/dashboard`}
                                    className={isActive('/admin/dashboard') ? 'active-link' : ''}
                                >
                                    Clients
                                </Link>
                            )}
                            <Link
                                to={`/edit-account/${userRole === 'admin' ? 'user' : 'client'}/${userId}`}
                                className={isActive(`/edit-account`) ? 'active-link' : ''}
                            >
                                Account
                            </Link>
                        </div>
                        <div className="navbar-account">
                            <button className="logout-button" onClick={handleLogout}>Logout</button>
                        </div>
                    </ul>
                </div>
            </nav>
        )
    );
};

export default Navbar;
