import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const handleForgotPassword = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, action: 'forgot_password' })
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    alert("Password reset email sent successfully!");
                    navigate("/"); // Redirect to home or login page
                } else {
                    alert("Failed to send reset email. " + (data.message || ""));
                }
            } else {
                alert("Failed to send reset email. Server returned an error.");
            }
        } catch (error) {
            console.error("Forgot Password error:", error);
            alert("An error occurred while requesting password reset. Please try again.");
        }
    };

    return (
        <div className="login">
            <form className="login-form" onSubmit={handleForgotPassword}>
                <img src="./echolink-vertical-white.png" alt="Logo" />
                <div className="login-form-container">
                    <h2>Forgot Password</h2>
                    {/* Material UI TextField for Email */}
                    <TextField
                        id="email"
                        label="Email"
                        type="email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        InputLabelProps={{
                            style: {
                                color: 'white', // Make the label white
                            },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "white", // White border color
                                },
                                "&:hover fieldset": {
                                    borderColor: "white", // White border on hover
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "white", // White border when focused
                                },
                            },
                        }}
                    />
                    <p>
                        <Link to="/">Back to Login</Link>
                    </p>
                    <button type="submit">Send Reset Link</button>
                </div>
            </form>
        </div>
    );
}

export default ForgotPassword;
