import React, { useState } from 'react';
import './FeedbackForm.css';
import { useNavigate, Link } from 'react-router-dom';
import { TextField, MenuItem, FormControlLabel, Checkbox } from '@mui/material';

async function determineSentiment(text) {
  const url = 'https://echolink-ai-441d886cf02d.herokuapp.com/analyze';
  const data = { text };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const result = await response.json();
      return result.sentiment;
    } else {
      console.error(`Error calling Flask API: ${response.status} - ${response.statusText}`);
      return 'Neutral'; // Fallback sentiment
    }
  } catch (error) {
    console.error('Error calling Flask API:', error);
    return 'Neutral'; // Fallback sentiment
  }
}

function FeedbackForm({ onNewFeedback, clientId, managers }) {
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [category, setCategory] = useState("");
  const [otherCategory, setOtherCategory] = useState(""); // For "Other" category input
  const [sentiment, setSentiment] = useState("AI Determined");
  const [rating, setRating] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [managerId, setManagerId] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // NEW: Opt-In checkboxes
  const [optInEmail, setOptInEmail] = useState(false);
  const [optInSMS, setOptInSMS] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim() || !email.trim() || !phone.trim()) {
      setErrorMessage("All contact fields (Name, Email, and Phone) are required.");
      return;
    }

    const finalCategory = category === "Other" ? otherCategory : category;

    if (category === "Other" && !otherCategory.trim()) {
      setErrorMessage("Please specify a category for 'Other'.");
      return;
    }

    // 1) Prepare the feedback object
    const newFeedback = {
      text,
      category: finalCategory,
      sentiment,
      rating,
      client_id: clientId,
      name,
      email,
      phone,
      manager_id: managerId,
    };

    try {
      // 2) Submit Feedback
      const feedbackResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/feedback.php`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newFeedback),
        }
      );

      const aiSentiment = await determineSentiment(text);

      if (!feedbackResponse.ok) {
        setErrorMessage("Failed to submit feedback.");
        return;
      }

      // If feedback submission is successful
      const feedbackData = await feedbackResponse.json();
      onNewFeedback(feedbackData); // optional callback

      // 3) If user checked any opt-in checkboxes, create an opt-in record
      if (optInEmail || optInSMS) {
        // Prepare optin data
        const optInData = {
          client_id: clientId,
          name,
          email: optInEmail ? email : null,
          phone: optInSMS ? phone : null,
          opt_in_email: optInEmail ? 1 : 0,
          opt_in_sms: optInSMS ? 1 : 0,
        };

        try {
          const optInResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/optin.php`,
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(optInData),
            }
          );

          if (!optInResponse.ok) {
            const errData = await optInResponse.json();
            console.warn("Opt-in request failed:", errData.message);
            // You could show an error message to the user or ignore it if optional
          }
        } catch (error) {
          console.error("Error submitting opt-in data:", error);
          // If you need to, handle an error message here
        }
      }

      // 4) Redirect to success page with the AI-determined sentiment
      navigate(`/client/${clientId}/success?sentiment=${aiSentiment}`, {
        replace: true,
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setErrorMessage("Error submitting feedback. Please try again.");
    }
  };

  const commonTextFieldStyles = {
    "& .MuiOutlinedInput-root": {
      color: "white", // White text for inputs
      marginBottom: '1em',
      "& fieldset": { borderColor: "white" },
      "&:hover fieldset": { borderColor: "white" },
      "&.Mui-focused fieldset": { borderColor: "white" },
    },
    "& .MuiInputLabel-root": { color: "white" }, // White label text
    "& .MuiSelect-icon": { color: "white" }, // White dropdown icon
  };

  return (
    <form onSubmit={handleSubmit} className="right-content">
      <h2>Feedback Form</h2>
      {submitSuccess && <p className="success-message">Thank you for your feedback!</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}

      <TextField
        id="feedback"
        label="Feedback Message:"
        variant="outlined"
        multiline
        value={text}
        onChange={(e) => setText(e.target.value)}
        className="feedback-input"
        sx={commonTextFieldStyles}
      />

      <TextField
        select
        label="Select Inquiry Category"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        variant="outlined"
        sx={commonTextFieldStyles}
      >
        {["Equipment", "Pay", "Mileage", "Benefits", "Home Time", "People", "Support", "Safety", "Other"].map((cat) => (
          <MenuItem
            key={cat}
            value={cat}
            style={{ color: "white", backgroundColor: "grey" }}
          >
            {cat}
          </MenuItem>
        ))}
      </TextField>

      {category === "Other" && (
        <TextField
          id="other-category"
          label="Specify Category"
          variant="outlined"
          value={otherCategory}
          onChange={(e) => setOtherCategory(e.target.value)}
          className="feedback-input"
          sx={commonTextFieldStyles}
        />
      )}

      <TextField
        select
        label="Select Manager"
        value={managerId}
        onChange={(e) => setManagerId(e.target.value)}
        variant="outlined"
        sx={commonTextFieldStyles}
      >
        {managers.map((manager) => (
          <MenuItem
            key={manager.id}
            value={manager.id}
            style={{ color: "white", backgroundColor: "grey" }}
          >
            {manager.name}
          </MenuItem>
        ))}
      </TextField>

      <div className="contact-fields">
        <TextField
          label="Your Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={commonTextFieldStyles}
        />
        <TextField
          label="Your Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={commonTextFieldStyles}
        />
        <TextField
          label="Your Phone"
          variant="outlined"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          sx={commonTextFieldStyles}
        />
      </div>

      {/* NEW: Opt-In Checkboxes */}
      <div className="optin-checkboxes">
        <FormControlLabel
          control={
            <Checkbox
              checked={optInEmail}
              onChange={(e) => setOptInEmail(e.target.checked)}
              sx={{ color: 'white' }}
            />
          }
          label={<span style={{ color: 'white' }}>Opt in for Email updates</span>}
        />
        
        <p className='sms-description'>I consent to be contacted by or on behalf of National Carriers via email. My information will not be shared. <Link to="/terms">Privacy Policy</Link></p>

        <FormControlLabel
          control={
            <Checkbox
              checked={optInSMS}
              onChange={(e) => setOptInSMS(e.target.checked)}
              sx={{ color: 'white' }}
            />
          }
          label={<span style={{ color: 'white' }}>Opt in for SMS updates</span>}
        />

          <p className='sms-description'>I consent to be contacted by or on behalf of National Carriers via phone call. My information will not be shared. <Link to="/terms">Privacy Policy</Link></p>

          <p className='sms-description'>I consent to be contacted by or on behalf of National Carriers via SMS. Messages will not exceed 2-4 per month. Standard messaging and data rates apply. Text STOP to opt out at any time.</p>

      </div>

      <button type="submit" style={{ marginTop: '1em' }}>Submit Feedback</button>
    </form>
  );
}

export default FeedbackForm;
