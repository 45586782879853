import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FeedbackCharts from './components/FeedbackCharts';
import Odometer from './components/Odometer';
import './ClientFeedbackPage.css';
import TextField from '@mui/material/TextField';
import OptInsModal from './OptInsModal';
import MenuItem from '@mui/material/MenuItem';

function ClientFeedbackPage() {
    const { clientId } = useParams();
    const [clientDetails, setClientDetails] = useState(null);
    const [feedbacks, setFeedbacks] = useState([]);
    const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
    const [managers, setManagers] = useState([]);
    const [selectedManager, setSelectedManager] = useState(""); // State for manager filter
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [feedbackVolume, setFeedbackVolume] = useState(0);
    const [inquiryCategoryVolume, setInquiryCategoryVolume] = useState({});
    const [managerResponseVolume, setManagerResponseVolume] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [keyword, setKeyword] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [feedbacksPerPage] = useState(20);  
    const [showOptIns, setShowOptIns] = useState(false);

    // Keep the sentimentUpdating for the PATCH request
    const [sentimentUpdating, setSentimentUpdating] = useState(false);

    // Instead of tags, store categories for filtering
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(""); // Empty string means "All Categories"

    // Sentiment percentages
    const [positivePercentage, setPositivePercentage] = useState(0);
    const [neutralPercentage, setNeutralPercentage] = useState(0);
    const [negativePercentage, setNegativePercentage] = useState(0);

    const openOptInsModal = () => {
        setShowOptIns(true);
      };
    
      const closeOptInsModal = () => {
        setShowOptIns(false);
      };
    useEffect(() => {
        const fetchClientDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php?id=${clientId}`);
                const data = await response.json();

                if (response.ok && data) {
                    setClientDetails(data.id ? data : {});
                } else {
                    setError("Failed to load client details.");
                }
            } catch (error) {
                setError("An error occurred while fetching client details.");
            }
        };

        const fetchFeedbacks = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/feedback.php?client_id=${clientId}`);
                const data = await response.json();

                if (response.ok) {
                    const feedbackData = data.records || [];
                    setFeedbacks(feedbackData);
                    setFilteredFeedbacks(feedbackData);

                    // Extract unique categories
                    const uniqueCategories = new Set();
                    feedbackData.forEach((fb) => {
                        if (fb.category) {
                            uniqueCategories.add(fb.category);
                        }
                    });
                    setCategories([...uniqueCategories]);

                    // Extract unique managers
                    const uniqueManagers = [
                        ...new Map(feedbackData.map(fb => [fb.manager?.id, fb.manager])).values(),
                    ].filter(manager => manager?.id);
                    setManagers(uniqueManagers);

                    // Calculate metrics
                    setFeedbackVolume(feedbackData.length);

                    const categoryCounts = feedbackData.reduce((acc, feedback) => {
                        acc[feedback.category] = (acc[feedback.category] || 0) + 1;
                        return acc;
                    }, {});
                    setInquiryCategoryVolume(categoryCounts);

                    const managerResponses = feedbackData.filter(fb => fb.manager && fb.manager.id).length;
                    setManagerResponseVolume(managerResponses);

                    // Calculate sentiment percentages
                    const totalFeedbacks = feedbackData.length;
                    const positiveCount = feedbackData.filter(fb => fb.sentiment === 'Positive').length;
                    const neutralCount = feedbackData.filter(fb => fb.sentiment === 'Neutral').length;
                    const negativeCount = feedbackData.filter(fb => fb.sentiment === 'Negative').length;

                    setPositivePercentage(((positiveCount / totalFeedbacks) * 100).toFixed(2));
                    setNeutralPercentage(((neutralCount / totalFeedbacks) * 100).toFixed(2));
                    setNegativePercentage(((negativeCount / totalFeedbacks) * 100).toFixed(2));
                } else {
                    setError("Failed to load feedback.");
                }
            } catch (error) {
                setError("An error occurred while fetching feedback.");
            } finally {
                setLoading(false);
            }
        };

        fetchClientDetails();
        fetchFeedbacks();
    }, [clientId]);

    const handleSentimentChange = async (feedbackId, newSentiment) => {
        setSentimentUpdating(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/feedback.php`, {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: feedbackId, sentiment: newSentiment }),
            });

            if (response.ok) {
                const updatedFeedbacks = feedbacks.map(feedback =>
                    feedback.id === feedbackId ? { ...feedback, sentiment: newSentiment } : feedback
                );
                setFeedbacks(updatedFeedbacks);
                filterFeedbacks(updatedFeedbacks);
            } else {
                alert('Failed to update sentiment.');
            }
        } catch (error) {
            alert('An error occurred while updating the sentiment.');
        } finally {
            setSentimentUpdating(false);
        }
    };

    const filterFeedbacks = (feedbackList = feedbacks) => {
        const filtered = feedbackList.filter(feedback => {
            const feedbackDate = new Date(feedback.date_submitted);
            const start = startDate ? new Date(startDate) : null;
            const end = endDate ? new Date(endDate) : null;

            const keywordMatch = feedback.text
                .toLowerCase()
                .includes(keyword.toLowerCase());

            const managerMatch =
                selectedManager === null ||
                (feedback.manager && feedback.manager.id === selectedManager);

            // Category filter match
            const categoryMatch =
                selectedCategory === "" ||
                feedback.category === selectedCategory;

            return (
                (!start || feedbackDate >= start) &&
                (!end || feedbackDate <= end) &&
                keywordMatch &&
                managerMatch &&
                categoryMatch
            );
        });

        setFilteredFeedbacks(filtered);
    };

    const handleSearchChange = (e) => {
        setKeyword(e.target.value);
        filterFeedbacks();
    };

    const handleDateChange = () => {
        filterFeedbacks();
    };

    const handleManagerChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedManager(selectedValue === "" ? null : parseInt(selectedValue, 10));
        filterFeedbacks();
    };

    // Handler for category filter
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        filterFeedbacks();
    };

    const indexOfLastFeedback = currentPage * feedbacksPerPage;
    const indexOfFirstFeedback = indexOfLastFeedback - feedbacksPerPage;
    const currentFeedbacks = filteredFeedbacks.slice(indexOfFirstFeedback, indexOfLastFeedback);
    const totalPages = Math.ceil(filteredFeedbacks.length / feedbacksPerPage);

    const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

    const renderWidgets = () => (
        <div className="widget-grid">
            <div className="widget-container">
                <div className="widget">
                    <h3>Average Feedback</h3>
                    <p>Positive: {positivePercentage}%</p>
                    <p>Neutral: {neutralPercentage}%</p>
                    <p>Negative: {negativePercentage}%</p>
                </div>
                <div className="widget">
                    <h3>Total Submissions</h3>
                    <Odometer value={feedbackVolume} />
                </div>
                <div className="widget">
                    <h3>Manager Responses</h3>
                    <Odometer value={managerResponseVolume} />
                </div>
            </div>

            <div className="inquiry-widget">
                <h3>Inquiry Categories</h3>
                {Object.entries(inquiryCategoryVolume).map(([category, count]) => (
                    <p className="inquiry-widget-row" key={category}>
                        <p>{category}:</p>
                        <p>{count}</p>
                    </p>
                ))}
            </div>
        </div>
    );

    function decodeHTMLEntities(str) {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
    }

    const getSentimentClass = (sentiment) => {
        switch (sentiment) {
            case 'Positive':
                return 'sentiment-positive';
            case 'Neutral':
                return 'sentiment-neutral';
            case 'Negative':
                return 'sentiment-negative';
            default:
                return '';
        }
    };

    return (
        <div className="client-feedback-container">
            <div className="overlay">
                <div className="feedback-container">
                    {clientDetails?.logo && (
                        <div className="client-logo-container">
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL}/${clientDetails.logo}`}
                                alt={`${clientDetails.name} Logo`}
                                className="client-logo"
                            />
                        </div>
                    )}
                    <div className="date-filter">
                        {/* Search by keyword */}
                        <div className="keyword-search">
                            <TextField
                                id="keyword"
                                label="Search"
                                variant="outlined"
                                className="keyword-search-input"
                                value={keyword}
                                onChange={handleSearchChange}
                                InputLabelProps={{ style: { color: 'white' } }}
                            />
                        </div>

                        {/* Filter by Manager */}
                        <div className="keyword-search">
                            <TextField
                                select
                                label="Filter by Manager"
                                value={selectedManager}
                                onChange={handleManagerChange}
                                variant="outlined"
                                className="keyword-search-input"
                                InputLabelProps={{ style: { color: 'white' } }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        color: "white",
                                    },
                                    "& .MuiSelect-icon": {
                                        color: "white",
                                    },
                                    "& .MuiMenuItem-root": {
                                        color: "white",
                                    },
                                }}
                            >
                                <MenuItem value="">All Managers</MenuItem>
                                {managers.map(manager => (
                                    <MenuItem key={manager.id} value={manager.id}>
                                        {manager.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        {/* Filter by Category */}
                        <div className="keyword-search">
                            <TextField
                                select
                                label="Filter by Category"
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                                variant="outlined"
                                className="keyword-search-input"
                                InputLabelProps={{ style: { color: 'white' } }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        color: "white",
                                    },
                                    "& .MuiSelect-icon": {
                                        color: "white",
                                    },
                                    "& .MuiMenuItem-root": {
                                        color: "white",
                                    },
                                }}
                            >
                                <MenuItem value="">All Categories</MenuItem>
                                {categories.map(cat => (
                                    <MenuItem key={cat} value={cat}>
                                        {cat}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        {/* Date Range Filter */}
                        <div className="date-filter-row">
                            <label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </label>
                            <label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </label>
                            <button onClick={handleDateChange}>Filter</button>
                        </div>
                    </div>
                </div>
                {/* <button onClick={openOptInsModal} style={{ marginBottom: '20px' }}>
                  View Client Opt-Ins
                </button> */}
                {showOptIns && (
                  <OptInsModal
                    clientId={clientId}
                    onClose={closeOptInsModal}
                  />
                )}
                {renderWidgets()}
                
                {filteredFeedbacks.length > 0 && (
                    <div className="chart-container">
                        <FeedbackCharts feedbacks={filteredFeedbacks} />
                    </div>
                )}
                {loading ? (
                    <p>Loading feedback...</p>
                ) : error ? (
                    <p className="error-message">{error}</p>
                ) : (
                    <div className="feedback-content">
                        <ul className="feedback-list">
                            {currentFeedbacks.map(feedback => (
                                <li key={feedback.id} className="feedback-item">
                                    <div className="feedback-list-content">
                                        <p className="feedback-dates">
                                            Date Submitted: {feedback.date_submitted}
                                        </p>
                                        <p className="feedback-dates">
                                            Name: {feedback.name}
                                        </p>
                                        <p className="feedback-dates">
                                            Email: {feedback.email}
                                        </p>
                                        <p className="feedback-dates">
                                            Phone: {feedback.phone}
                                        </p>
                                        <p>{decodeHTMLEntities(feedback.text)}</p>

                                        {feedback.manager && feedback.manager.id && (
                                            <p className="feedback-manager">
                                                <strong>Associated Manager:</strong> {feedback.manager.name}
                                            </p>
                                        )}
                                        <div className="category-tag">
                                            {feedback.category}
                                        </div>
                                    </div>
                                    <div className="feedback-actions">
                                        <select
                                            className={`sentiment-select ${getSentimentClass(feedback.sentiment)}`}
                                            value={feedback.sentiment}
                                            disabled={sentimentUpdating}
                                            onChange={(e) =>
                                                handleSentimentChange(feedback.id, e.target.value)
                                            }
                                        >
                                            <option value="Positive">Positive</option>
                                            <option value="Neutral">Neutral</option>
                                            <option value="Negative">Negative</option>
                                        </select>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        
                        <div className="pagination">
                            <button
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                Prev
                            </button>
                            <span>
                                {currentPage} / {totalPages}
                            </span>
                            <button
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ClientFeedbackPage;
