import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './OptInPage.css';
import { TextField, FormControlLabel, Checkbox, Button } from '@mui/material';

function OptInPage() {
  const { clientId } = useParams();  // to grab clientId from the URL
  const [clientData, setClientData] = useState(null);

  // New state for 'name'
  const [fullName, setFullName] = useState("");

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [optInEmail, setOptInEmail] = useState(false);
  const [optInSMS, setOptInSMS] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Fetch client information
  useEffect(() => {
    const fetchClientData = async () => {
      if (!clientId) return;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/clients.php?id=${clientId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch client data");
        }
        const data = await response.json();
        setClientData(data);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    };

    fetchClientData();
  }, [clientId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!optInEmail && !optInSMS) {
      setSuccessMessage("Please select at least one option to opt in.");
      return;
    }

    // Prepare the data to be submitted
    const optInData = {
      client_id: clientId,         // from URL
      name: fullName || null,      // new: send 'name' to the backend
      email: optInEmail ? email : null,
      phone: optInSMS ? phone : null,
      opt_in_email: optInEmail ? 1 : 0,
      opt_in_sms: optInSMS ? 1 : 0,
    };
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/optin.php`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(optInData),
    });

    if (response.ok) {
      setSuccessMessage("You have successfully opted in for updates!");
      // Clear form, etc...
    } else {
      // If status code is 409, it's a duplicate
      const result = await response.json();
      setSuccessMessage(result.message || "Failed to process your opt-in request.");
    }
  } catch (error) {
    console.error("Error submitting opt-in data:", error);
    setSuccessMessage("Received! Thank you for opting in.");
  }
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/optin.php`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(optInData),
    });

    if (response.ok) {
      setSuccessMessage("You have successfully opted in for updates!");
      // Clear form, etc...
    } else {
      // If status code is 409, it's a duplicate
      const result = await response.json();
      setSuccessMessage(result.message || "Failed to process your opt-in request.");
    }
    } catch (error) {
      console.error("Error submitting opt-in data:", error);
      setSuccessMessage("Received! Thank you for opting in.");
    }
  };

  return (
    <div className="opt-in-page-container">
      {/* If the clientData has loaded, display the logo and name */}
      {clientData && (
        <div className="client-header">
          {clientData.logo && (
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/${clientData.logo}`}
              alt={`${clientData.name} Logo`}
              style={{ maxHeight: '400px', marginBottom: '1rem' }}
            />
          )}
          <h1>Stay Connected with {clientData.name}</h1>
        </div>
      )}

      {/* Fallback heading if client data is not yet available */}
      {!clientData && <h1>Stay Connected</h1>}

      <p>Opt in to receive updates and notifications via email or SMS.</p>

      {successMessage && <p className="success-message">{successMessage}</p>}

      <form onSubmit={handleSubmit} className="opt-in-form">
        {/* Name Field */}
        <TextField
          label="Name"
          variant="outlined"
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          fullWidth
          sx={{
            marginBottom: '1em',
            '& .MuiOutlinedInput-root': {
              color: 'white',
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'white',
            },
          }}
          InputLabelProps={{ style: { color: 'white' } }}
          InputProps={{ style: { color: 'white' } }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={optInEmail}
              onChange={(e) => setOptInEmail(e.target.checked)}
              sx={{ color: 'white' }}
            />
          }
          label={<span style={{ color: 'white' }}>Opt in for Email Updates</span>}
        />
        {optInEmail && (
            <>
          <TextField
            label="Email Address"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            sx={{
              marginBottom: '1em',
              '& .MuiOutlinedInput-root': {
                color: 'white',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'white',
              },
            }}
            InputLabelProps={{ style: { color: 'white' } }}
            InputProps={{ style: { color: 'white' } }}
          />
                    <p className='sms-description'>I consent to be contacted by or on behalf of National Carriers via email. My information will not be shared. <Link to="/terms">Privacy Policy</Link></p>
          </>
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={optInSMS}
              onChange={(e) => setOptInSMS(e.target.checked)}
              sx={{ color: 'white' }}
            />
          }
          label={<span style={{ color: 'white' }}>Opt in for SMS Updates</span>}
        />
        {optInSMS && (
            <>
          <TextField
            label="Phone Number"
            variant="outlined"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            fullWidth
            sx={{
              marginBottom: '1em',
              '& .MuiOutlinedInput-root': {
                color: 'white',
                '& fieldset': {
                  borderColor: 'white',
                },
                '&:hover fieldset': {
                  borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'white',
                },
              },
              '& .MuiInputLabel-root': {
                color: 'white',
              },
            }}
            InputLabelProps={{ style: { color: 'white' } }}
            InputProps={{ style: { color: 'white' } }}
          />
          <p className='sms-description'>I consent to be contacted by or on behalf of National Carriers via phone call. My information will not be shared. <Link to="/terms">Privacy Policy</Link></p>

          <p className='sms-description'>I consent to be contacted by or on behalf of National Carriers via SMS. Messages will not exceed 2-4 per month. Standard messaging and data rates apply. Text STOP to opt out at any time.</p>

          </>
        )}

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default OptInPage;
