import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaPlus } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import './AdminDashboard.css'; // Import CSS for custom styling

function AdminDashboard() {
  const [clients, setClients] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php`);
      
      if (response.ok) {
        const data = await response.json();
        if (data && data.records) {
          setClients(data.records);
        } else {
          setError("No clients found.");
        }
      } else {
        setError(`Failed to load clients: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      setError("An error occurred while fetching clients.");
    }
  };

  const handleDeleteClient = async (clientId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this client?");
    if (!confirmDelete) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php?id=${clientId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        await fetchClients(); // Refresh the client list after deletion
      } else {
        setError("Failed to delete client.");
      }
    } catch (error) {
      console.error("Error deleting client:", error);
      setError("An error occurred while deleting the client.");
    }
  };

  return (
    <div className="admin-dashboard-container">
      <div className="admin-dashboard-header">
        <h2>Clients</h2>
        {/* Add link to Create User page */}
        <div className="create-user-link">
          <Link to="/create-user" target="_blank" className="create-user-button">
            <strong><FaPlus /> Add Client</strong>
          </Link>
        </div>
      </div>

      {error && <p className="error-message">{error}</p>}
      <div className="existing-clients">
        <ul className="clients-list">
          {clients.map(client => (
            <li key={client.id} className="client-item">
              <div className="client-info-home">
                {/* The Edit (gear) link that routes to EditAccount */}
                <Link to={`/edit-account/client/${client.id}`} className="client-link edit-link">
                  <FaGear />
                </Link>
                <strong>{client.name}</strong>
              </div>
              <div className="client-actions">

                <Link to={`/client/${client.id}/optin`} className="client-link feedback-link">
                  Opt-In
                </Link>
                <Link to={`/client/${client.id}/feedbacks`} className="client-link feedback-link">
                  Feedback Summary
                </Link>
                <Link to={`/client/${client.id}/submit-feedback`} className="client-link submit-link">
                  Submit Feedback Page
                </Link>
                {/* Uncomment if you want to enable a delete button:
                <button onClick={() => handleDeleteClient(client.id)} className="delete-client-button">
                  Delete
                </button> */}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AdminDashboard;
