import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FaShare } from "react-icons/fa6";
import axios from 'axios';
import './SuccessPage.css';

function SuccessPage() {
    const { clientId } = useParams();
    const [shareLink, setShareLink] = useState(null);

    const searchParams = new URLSearchParams(window.location.search);
    const sentiment = searchParams.get('sentiment') || '';

    useEffect(() => {
        // Fetch the client data so we get the share_link
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/api/clients.php?id=${clientId}`)
            .then((res) => {
                setShareLink(res.data.share_link || '');
            })
            .catch((err) => console.error(err));
    }, [clientId]);

    return (
        <div className="success-page-container">
            <h1>Thank You for Your Feedback!</h1>
            <p>Your feedback has been submitted successfully.</p>

            {sentiment.toLowerCase() === 'positive' && (
                <div className="share-container">
                    <p>Did you have a positive experience? Share it!</p>
                    <a
                        href={
                            shareLink
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="share-button"
                    >
                        <FaShare />
                        Share
                    </a>
                </div>
            )}
        </div>
    );
}

export default SuccessPage;
