import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from 'chart.js';
import './FeedbackCharts.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement);

function FeedbackCharts({ feedbacks }) {
    const categorizeFeedback = (feedbacks) => {
        const categories = { positive: 0, neutral: 0, negative: 0 };

        feedbacks.forEach(feedback => {
            if (feedback.sentiment === 'Positive') categories.positive++;
            else if (feedback.sentiment === 'Neutral') categories.neutral++;
            else if (feedback.sentiment === 'Negative') categories.negative++;
        });

        return categories;
    };

    const feedbackCategories = categorizeFeedback(feedbacks);

    const calculateManagerSentiments = () => {
        const managerSentiments = {};

        feedbacks.forEach((feedback) => {
            if (feedback.manager && feedback.manager.name) {
                const managerName = feedback.manager.name;

                if (!managerSentiments[managerName]) {
                    managerSentiments[managerName] = { positive: 0, neutral: 0, negative: 0, total: 0 };
                }

                if (feedback.sentiment === 'Positive') managerSentiments[managerName].positive++;
                else if (feedback.sentiment === 'Neutral') managerSentiments[managerName].neutral++;
                else if (feedback.sentiment === 'Negative') managerSentiments[managerName].negative++;

                managerSentiments[managerName].total++;
            }
        });

        // Convert raw counts to percentages
        for (const manager in managerSentiments) {
            const sentimentData = managerSentiments[manager];
            sentimentData.positive = (sentimentData.positive / sentimentData.total) * 100;
            sentimentData.neutral = (sentimentData.neutral / sentimentData.total) * 100;
            sentimentData.negative = (sentimentData.negative / sentimentData.total) * 100;
        }

        return managerSentiments;
    };

    const managerSentiments = calculateManagerSentiments();

    const managerSentimentChartData = {
        labels: Object.keys(managerSentiments),
        datasets: [
            {
                label: 'Positive',
                data: Object.values(managerSentiments).map((data) => data.positive),
                backgroundColor: '#009A82',
            },
            {
                label: 'Neutral',
                data: Object.values(managerSentiments).map((data) => data.neutral),
                backgroundColor: '#EBC033',
            },
            {
                label: 'Negative',
                data: Object.values(managerSentiments).map((data) => data.negative),
                backgroundColor: '#C42727',
            },
        ],
    };

    const barChartData = {
        labels: ['Positive', 'Neutral', 'Negative'],
        datasets: [
            {
                label: 'Feedback Count',
                data: [feedbackCategories.positive, feedbackCategories.neutral, feedbackCategories.negative],
                backgroundColor: ['#009A82', '#EBC033', '#C42727'],
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#fff', // White labels for dark mode
                },
            },
        },
        scales: {
            x: {
                ticks: {
                    color: '#fff', // White ticks
                },
                grid: {
                    color: '#555', // Dark grid lines
                },
            },
            y: {
                ticks: {
                    color: '#fff', // White ticks
                },
                grid: {
                    color: '#555', // Dark grid lines
                },
            },
        },
    };

    return (
        <div className="feedback-charts-container">
            <div className="chart-wrapper">
                <div className="chart-item">
                    <Bar data={barChartData} options={chartOptions} />
                </div>
                <div className="chart-item">
                    <Bar data={managerSentimentChartData} options={chartOptions} />
                </div>
            </div>
        </div>
    );
}

export default FeedbackCharts;
